import {
    Euler,
    EventDispatcher,
} from 'three'

var FirstPersonController = function (object, domElement) {

    if (domElement === undefined) {

        console.warn('THREE.FirstPersonController: The second parameter "domElement" is now mandatory.');
        domElement = document;
    }

    this.object = object;
    this.domElement = domElement;

    // internals
    this.mousePressed = false;
    this.mouseMoving = false;

    // Set to constrain the pitch of the camera
    // Range is 0 to Math.PI radians
    this.minPolarAngle = 0; // radians
    this.maxPolarAngle = Math.PI; // radians

    var euler = new Euler( 0, 0, 0, 'YXZ' );
    var PI_2 = Math.PI / 2;
    var changeEvent = { type: 'change' };

    // stopping the movement when interacting with an object we want to focus on.
    this.threeDimensionsOnly = true;


    // internals
    this.touchPressed = false;
    this.touchMoving = false;

    if (this.domElement !== document) {

        this.domElement.setAttribute('tabindex', -1);

    }

    this.onMouseDown = function (event) {

        if (this.domElement !== document) {

            this.domElement.focus();

        }
        event.preventDefault();

        if (this.mouseMoving) {
            event.stopPropagation();
        } else{
            this.mousePressed = true;
        }

    };

    this.onMouseUp = function (event) {

        event.preventDefault();
        event.stopPropagation();

        this.mousePressed = false;

    };

    this.onMouseOut = function (event) {

        event.preventDefault();
        event.stopPropagation();

        if (this.mousePressed){
            this.mousePressed = false;
        }
    };

    this.onMouseMove =	function onMouseMove( event ) {
        if (!this.mousePressed) return

        var movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
        var movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;

        euler.setFromQuaternion( this.object.quaternion );

        euler.y -= movementX * 0.0015;
        euler.x -= movementY * 0.0015;

        euler.x = Math.max( PI_2 - this.maxPolarAngle, Math.min( PI_2 - this.minPolarAngle, euler.x ) );

        this.object.quaternion.setFromEuler( euler );

        this.dispatchEvent( changeEvent );

    }

    function contextmenu(event) {
        event.preventDefault();
    }

    this.dispose = function () {

        this.domElement.removeEventListener('contextmenu', contextmenu, false);
        this.domElement.removeEventListener('mousemove', _onMouseMove, false);
        this.domElement.removeEventListener('mousedown', _onMouseDown, false);
        this.domElement.removeEventListener('mouseup', _onMouseUp, false);
        this.domElement.removeEventListener('mouseout', _onMouseOut, false);
        /*

                this.domElement.removeEventListener('touchmove', _onTouchMove, false);
                this.domElement.removeEventListener('touchstart', _onTouchDown, false);
                this.domElement.removeEventListener('touchend', _onTouchUp, false);
                this.domElement.removeEventListener('touchcancel', _onTouchOut, false);
        */


    };

    var _onMouseMove = bind(this, this.onMouseMove);
    var _onMouseDown = bind(this, this.onMouseDown);
    var _onMouseUp = bind(this, this.onMouseUp);
    var _onMouseOut = bind(this, this.onMouseOut);


    this.domElement.addEventListener('contextmenu', contextmenu, false);
    this.domElement.addEventListener('mousemove', _onMouseMove, false);
    this.domElement.addEventListener('mousedown', _onMouseDown, false);
    this.domElement.addEventListener('mouseup', _onMouseUp, false);
    this.domElement.addEventListener('mouseout', _onMouseOut, false);




    function bind(scope, fn) {

        return function () {

            fn.apply(scope, arguments);

        };
    }


    this.isThreeDimensionOnly =	function isThreeDimensionOnly(value) {
        //this.threeDimensionsOnly = !this.threeDimensionsOnly
        this.threeDimensionsOnly = value
        if (!this.threeDimensionsOnly) {
            this.domElement.removeEventListener('mousedown', _onMouseDown, false);
        } else {
            this.domElement.addEventListener('mousedown', _onMouseDown, false);
        }
    }


    /*
        this.onTouchDown = function (event) {

            if (this.domElement !== document) {

                this.domElement.focus();

            }
            event.preventDefault();

            if (this.touchMoving) {
                event.stopPropagation();
            } else{
                this.touchPressed = true;
            }

        };

        this.onTouchUp = function (event) {

            event.preventDefault();
            event.stopPropagation();

            this.touchPressed = false;

        };

        this.onTouchOut = function (event) {

            event.preventDefault();
            event.stopPropagation();

            if (this.touchPressed){
                this.touchPressed = false;
            }
        };

        this.onTouchMove =	function onTouchMove( event ) {
            if (!this.touchPressed) return

            var movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
            var movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;

            euler.setFromQuaternion( this.object.quaternion );

            euler.y -= movementX * 0.0015;
            euler.x -= movementY * 0.0015;

            euler.x = Math.max( PI_2 - this.maxPolarAngle, Math.min( PI_2 - this.minPolarAngle, euler.x ) );

            this.object.quaternion.setFromEuler( euler );

            this.dispatchEvent( changeEvent );

        }*/

};

FirstPersonController.prototype = Object.create( EventDispatcher.prototype );
FirstPersonController.prototype.constructor = FirstPersonController;
export {FirstPersonController};
